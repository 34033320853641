import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Main from "./Main";

const App = React.memo(() => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main englishWordMode={false} />} />
        <Route path="/ew" element={<Main englishWordMode={true} />} />
      </Routes>
    </BrowserRouter>
  );
});

export default App;
