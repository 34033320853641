import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./App.css";

const Main = React.memo((props: { englishWordMode: boolean }) => {
  const [text, setText] = useState("");
  const [sortKey, setSortKey] = useState<
    "character" | "countAsc" | "countDesc"
  >("character");
  const [result, setResult] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    if (props.englishWordMode) {
      const allWords = text
        .replaceAll(/-\n/g, "")
        .replaceAll(/\n/g, " ")
        .replaceAll(/\./g, "")
        .replaceAll(/,/g, "")
        .toLowerCase()
        .split(" ")
        .filter((x) => x !== "");

      const elements = allWords.map((word) => ({
        [word]: allWords.filter((x) => x === word).length
      }));

      const dic = Object.assign({}, ...elements) as {
        [key: string]: number;
      };

      setResult(dic);
    } else {
      const allCharacters = text
        .replaceAll(/[　 \n]/g, "")
        .split("")
        .filter((x) => x !== "");

      const elements = allCharacters.map((charcter) => ({
        [charcter]: allCharacters.filter((x) => x === charcter).length
      }));

      const dic = Object.assign({}, ...elements) as {
        [key: string]: number;
      };

      setResult(dic);
    }
  }, [text, sortKey, props.englishWordMode]);

  const sorted = Object.entries(result).sort(([aKey, aValue], [bKey, bValue]) =>
    sortKey === "character"
      ? aKey.localeCompare(bKey)
      : sortKey === "countAsc"
      ? Math.sign(aValue - bValue) * 2 + aKey.localeCompare(bKey)
      : sortKey === "countDesc"
      ? Math.sign(bValue - aValue) * 2 + aKey.localeCompare(bKey)
      : 0
  );

  const rows = sorted.map(([k, v]) => (
    <TableRow key={k}>
      <TableCell>{k}</TableCell>
      <TableCell>{v}</TableCell>
    </TableRow>
  ));

  const clipboardRows = [
    props.englishWordMode ? "word\tcount" : "文字\t出現回数",
    ...sorted.map(([k, v]) => `${k}\t${v}`)
  ].join("\r\n");

  return (
    <>
      <Paper sx={{ margin: "10px", padding: "10px" }}>
        <TextField
          multiline
          fullWidth
          label={
            props.englishWordMode ? "Enter the text here" : "ここに本文を入力"
          }
          minRows={5}
          maxRows={5}
          value={text}
          onChange={(event) => {
            setText(event.target.value);
          }}
        />
      </Paper>
      <Paper sx={{ margin: "10px", padding: "10px" }}>
        <Button
          variant="contained"
          sx={{ margin: "5px" }}
          onClick={() => {
            setSortKey("character");
          }}
        >
          {props.englishWordMode ? "sort by word" : "文字順に並べ替え"}
        </Button>
        <Button
          variant="contained"
          sx={{ margin: "5px" }}
          onClick={() => {
            setSortKey("countDesc");
          }}
        >
          {props.englishWordMode
            ? "sort by count (descending)"
            : "出現回数順に並べ替え(多 → 少)"}
        </Button>
        <Button
          variant="contained"
          sx={{ margin: "5px" }}
          onClick={() => {
            setSortKey("countAsc");
          }}
        >
          {props.englishWordMode
            ? "sort by count (ascending)"
            : "出現回数順に並べ替え(少 → 多)"}
        </Button>
        <Button
          variant="contained"
          sx={{ margin: "5px" }}
          disabled={navigator.clipboard === undefined}
          onClick={() => {
            navigator.clipboard
              .writeText(clipboardRows)
              .then(() =>
                alert(props.englishWordMode ? "copied!" : "コピーしました。")
              )
              .catch((reason) =>
                alert(
                  `${
                    props.englishWordMode
                      ? "could not copy..."
                      : "コピーできませんでした。"
                  }${reason.message}`
                )
              );
          }}
        >
          {props.englishWordMode ? "copy the result" : "結果をコピー"}
        </Button>
        <TableContainer component="div">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  {props.englishWordMode ? "words" : "文字"}
                </TableCell>
                <TableCell>
                  {props.englishWordMode ? "count of appearance" : "出現回数"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
});

export default Main;
